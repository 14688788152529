<template>
  <section class="vh-lg-100 d-flex align-items-center main">
    <div class="overlay">
      <div class="container">
        <!-- MODAL -->
        <div
          class="modal fade"
          id="login-modal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="login-modal"
        >
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-body">
                <div class="py-7 text-center">
                  <span class="modal-icon display-1-lg"
                    ><span class="fas fa-check-double"></span
                  ></span>
                  <h2 class="h4 modal-title my-3">Login Successful</h2>
                  <p>
                    Please wait while you are automatically forwarded to your
                    dashboard.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- END MODAL -->
        <div class="row justify-content-center" style="height: 100vh">
          <div class="col-12 d-flex align-items-center justify-content-center">
            <div
              class="signin-inner text-light translucent-white my-3 my-lg-0 shadow-soft rounded p-4 p-lg-5 w-100 fmxw-500"
            >
              <div class="text-center text-md-center mb-4 mt-md-0">
                <h1 class="mb-0 h3">Facility Login</h1>
              </div>
              <form @submit.prevent="onSubmit" class="mt-4">
                <!-- Form -->
                <div class="form-group mb-4">
                  <label for="email">Your Email</label>
                  <div class="input-group">
                    <span class="input-group-text bg-light" id="basic-addon1">
                      <span class="fas fa-envelope"></span>
                    </span>
                    <input
                      type="email"
                      @input="$v.email.$touch()"
                      v-model="email"
                      class="form-control pl-2"
                      placeholder="example@company.com"
                      id="email"
                      autofocus
                      required
                    />
                  </div>
                </div>
                <!-- End of Form -->
                <div class="form-group">
                  <!-- Form -->
                  <div class="form-group mb-4">
                    <label for="password">Your Password</label>
                    <div class="input-group">
                      <span class="input-group-text bg-light" id="basic-addon2">
                        <span class="fas fa-unlock-alt"></span>
                      </span>
                      <input
                        type="password"
                        @input="$v.password.$touch()"
                        v-model="password"
                        placeholder="Password"
                        class="form-control pl-2"
                        id="password"
                        required
                      />
                    </div>
                  </div>
                </div>

                <div class="error">
                  <p style="display: block">
                    <span style="color: transparent">:</span>
                    <span :style="{ color: 'red' }" v-if="!message"
                      >Your Email and/or Password do not match our
                      records.</span
                    >&nbsp;
                  </p>
                </div>
                <!-- End of Form -->
                <div
                  class="d-flex justify-content-between align-items-center mb-4"
                >
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      v-model="cookies"
                      @change="$v.cookies.$touch()"
                      type="checkbox"
                      id="cookies."
                    />
                    <label class="form-check-label pl-2" for="cookies">
                      I accept this site’s use of cookies.</label
                    >
                  </div>
                </div>
                <button
                  :disabled="
                    $v.$anyError ||
                    $v.cookies.$invalid ||
                    $v.password.$invalid ||
                    $v.email.$invalid
                  "
                  type="submit"
                  class="btn btn-block btn-primary"
                >
                  <i class="fas fa-sign-in-alt pr-3"></i> Login
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<style scoped>
.translucent-white {
  box-shadow: 0 0 2rem 0 rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  position: relative;
  z-index: 1;
  background: inherit;
  overflow: hidden;
  backdrop-filter: blur(3px);
}

.translucent-white:before {
  content: "";
  position: absolute;
  background: inherit;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: inset 0 0 2000px rgba(255, 255, 255, 0.2);
  margin: -20px;
}
section {
  background-image: linear-gradient(
      to bottom right,
      rgb(51, 51, 51),
      rgba(29, 29, 29, 0.295)
    ),
    url("https://emr.bedscribe.com/login/background.png");
  background-position: top left;
  background-repeat: no-repeat;
  background-color: darkgray;
  background-attachment: fixed;
  background-size: cover;
  height: 100vh;
}
div.overlay {
  background-image: url("https://emr.bedscribe.com/login/powered_by.png");
  background-position: bottom 5% center;
  background-repeat: no-repeat;
  background-color: transparent;
  background-attachment: fixed;
  background-size: 20rem;
  width: 100%;
  height: 100vh;
}
</style>

<script>
import axios from "../../axios-auth";
import { required, email, sameAs } from "vuelidate/lib/validators";
import bootstrapMin from "../../html&css/vendor/bootstrap/dist/js/bootstrap.min";

//Clean localstorage
localStorage.removeItem("userId");
localStorage.removeItem("medAuthToken");
localStorage.removeItem("photo");
localStorage.removeItem("displayName");

document.title = "Login";

let loginModal = {};
document.onreadystatechange = function () {
  loginModal = new bootstrapMin.Modal(
    document.getElementById("login-modal"),
    {}
  );
};
console.log(loginModal);

export default {
  data() {
    return {
      email: "",
      password: "",
      cookies: false,
      message: true,
      msgModalShow: false,
    };
  },
  validations: {
    email: {
      required,
      email,
    },
    password: {
      required,
    },
    cookies: {
      sameAs: sameAs(() => true),
    },
  },
  methods: {
    onSubmit() {
      const formData = {
        email: this.email,
        password: this.password,
      };
      console.log(formData);
      axios
        .post("/login", {
          email: this.email,
          password: this.password,
        })
        .then((res) => {
          console.log(res);
          this.message = res.data.loginSuccess;
          if (this.message) {
            console.log("True Login");
            loginModal.show();
            localStorage.setItem("userId", res.data.userId);
            localStorage.setItem("medAuthToken", res.data.medAuthToken);
            localStorage.setItem("photo", res.data.photo);
            localStorage.setItem("displayName", res.data.displayName);
            localStorage.setItem(
              "fullName",
              `${res.data.firstName} ${res.data.lastName}`
            );
            localStorage.setItem("nuxt-loading-enable-animation", true);
            setTimeout(function () {
              if (location.hostname === "app.bedscribe.com") {
                window.location.replace("https://app.bedscribe.com/");
              } else {
                window.location = "/activity";
              }
            }, 2000);
          } else {
            console.log("Bad Login");
          }
        })
        .catch((error) => console.log(error));
    },
  },
};
</script>
